import { get } from "../../utils/request";

interface Credentials {
  userName: string;
  password: string;
}

export const logIn = async (credentials: Credentials): Promise<unknown> => {
  const loginUrl = "/api/internal/session";

  const { userName, password } = credentials;

  const body = {
    user_name: userName,
    password,
  };

  const response = await fetch(loginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new Error("Login failed");
  }
};

export const verifyAuthStatus = async (): Promise<boolean> => {
  const verifyUrl = "/api/internal/session";

  try {
    await get(verifyUrl);
    return true;
  } catch (error) {
    // we mostly care about unauthorized, 401, but it seems like a good bet to
    // be overzealous
    return false;
  }
};
