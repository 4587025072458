import { getAuthToken } from "./persistance";

export class MaestroError extends Error {
  json: unknown;
  response: Response;

  constructor(message: string, json: unknown, response: Response) {
    super(message);
    this.response = response;
    this.json = json;
    this.name = "MaestroError";
  }
}

interface stringMap {
  [key: string]: string;
}
interface requestInterface {
  query?: stringMap;
  headers?: { [key: string]: string };
  body?: { [key: string]: unknown };
}

function buildHeader(optionHeaders?: stringMap) {
  const headers: stringMap = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...optionHeaders,
  };

  if (getAuthToken()) headers["Authorization"] = `Bearer ${getAuthToken()}`;
  return headers;
}

type RequestResponse<T> = Promise<T>;

function request<T>(
  method: string,
  url: string,
  { ...options }: requestInterface = {}
): RequestResponse<T> {
  const body = options.body && JSON.stringify(options.body);

  const headers = buildHeader(options.headers);

  return fetch(url, {
    ...options,
    method,
    body,
    headers,
  })
    .then((response) => {
      if (response.status == 204 || response.status === 202)
        return Promise.all([response, {}]);
      return Promise.all([response, failSafeJSONParse(response)]);
    })
    .then(([response, json]) => {
      if (response.status < 200 || response.status >= 300) {
        // Probably not the most beautiful way of defining an error
        const errorMessage =
          json.error || json.errors?.join(", ") || response.statusText;
        const error = new MaestroError(errorMessage, json, response);
        return Promise.reject(error);
      }
      return json;
    });
}

const failSafeJSONParse = async (response: Response) => {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (error) {
    return {};
  }
};

export const get: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>("GET", url, obj);

export const post: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>("POST", url, obj);

export const patch: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>("PATCH", url, obj);

export const put: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>("PUT", url, obj);

export const del: <T>(
  url: string,
  obj?: requestInterface
) => RequestResponse<T> = <T>(url: string, obj: requestInterface = {}) =>
  request<T>("DELETE", url, obj);
