import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Login from "./features/login/Login";
import MapiAuthorization from "./features/mapi_authorization/MapiAuthorization";
import Devices from "./features/devices/Devices";
import tw from "twin.macro";
import { IntlProvider } from "react-intl";
import { getAuthToken, clear as clearAuthToken } from "./utils/persistance";
import { verifyAuthStatus } from "./features/login/api";
import { FormattedMessage } from "react-intl";
import Loader from "./components/Loader";
import { store } from "./store";
import { Provider } from "react-redux";

const Container = tw.div`bg-grey-delta h-screen`;

const ProtectedRoutes = () => {
  const [fetchingAuthStatus, setFetchingAuthStatus] = useState(false);
  const history = useHistory();

  // TODO: handle Errors?
  const verifySession = () => {
    setFetchingAuthStatus(true);
    verifyAuthStatus().then((validSession) => {
      setFetchingAuthStatus(false);
      if (!validSession) {
        clearAuthToken();
        history.replace("/login");
      }
    });
  };

  useEffect(() => {
    if (getAuthToken()) {
      verifySession();
    } else {
      history.replace("/login");
    }
  }, []);

  if (fetchingAuthStatus) {
    return (
      <Loader
        label={
          <FormattedMessage
            id="app.veryfing-session"
            defaultMessage="Verifying session"
            description="Label on loading screen when session is being verified"
          />
        }
      />
    );
  } else {
    return (
      <Switch>
        <Route path="/authorization">
          <MapiAuthorization />
        </Route>
        <Route path="/devices">
          <Devices />
        </Route>
      </Switch>
    );
  }
};

class App extends React.Component {
  render(): React.ReactElement {
    return (
      <Provider store={store}>
        <IntlProvider locale="en" defaultLocale="en">
          <Container>
            <Router>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route
                  render={() => {
                    return <ProtectedRoutes />;
                  }}
                />
              </Switch>
            </Router>
          </Container>
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;
