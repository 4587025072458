import * as React from "react";

function SvgRedo(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.247 2.465l.157.153 2.141 2.016v-2.68c0-.373.281-.68.643-.722l.085-.005c.373 0 .68.28.722.643l.005.084V6.33a.731.731 0 01-.003.055L16 6.319a.733.733 0 01-.198.498l-.01.012a.732.732 0 01-.042.039l.052-.05a.733.733 0 01-.445.223l-.012.001a.732.732 0 01-.029.003l-.043.001h-4.365a.727.727 0 01-.084-1.45l.084-.005h2.531l-2.049-1.93a5.819 5.819 0 101.371 6.052.727.727 0 111.372.485 7.274 7.274 0 11-10.37-8.793 7.268 7.268 0 018.484 1.06z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRedo;
