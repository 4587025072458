import React, { ReactElement } from "react";
// @ts-expect-error png file
import LogoSrc from "../../assets/mevia-logo@2x.png";

const Logo = (): ReactElement => {
  return (
    <div className="text-center">
      <img
        className="mx-auto mb-2"
        src={LogoSrc}
        alt="Mevia logo"
        width="156px"
      />
      <h2 className="font-bold font-heading text-lg">Device management</h2>
    </div>
  );
};

export default Logo;
