/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect } from "react";
import tw, { css, styled, theme } from "twin.macro";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import Loader from "../../components/Loader";
import FetchError from "../../components/FetchError";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useGlobalFilter,
  CellProps,
  Column,
} from "react-table";
import GroupStyleButton from "../../components/GroupStyleButton";
import Checkbox from "../../components/Checkbox";
import ChevronDown from "../../components/icons/ChevronDown";
import ChevronUp from "../../components/icons/ChevronUp";
import Pagination from "../../components/Pagination";
import Header from "./Header";
import FilterSidebar from "./FilterSidebar";
import SettingsPanel from "./SettingsPanel";
import SearchField from "./SearchField";
import Spinner from "../../components/Spinner";
import Clock from "../../components/icons/Clock";
import CheckCircle from "../../components/icons/CheckCircle";
import {
  FilterSidebarProvider,
  useFilterSidebar,
} from "./FilterSidebar/context";
import { filter as filterDevices } from "./FilterSidebar/filter";
import { Device } from "./type";
import { fetchDevices } from "./api";
declare module "react-table" {
  interface TableOptions<D extends object>
    extends UseExpandedOptions<D>,
      UseFiltersOptions<D>,
      UseGlobalFiltersOptions<D>,
      UseGroupByOptions<D>,
      UsePaginationOptions<D>,
      UseResizeColumnsOptions<D>,
      UseRowSelectOptions<D>,
      UseRowStateOptions<D>,
      UseSortByOptions<D> {}

  interface TableInstance<D extends object = {}>
    extends UseColumnOrderInstanceProps<D>,
      UseExpandedInstanceProps<D>,
      UseFiltersInstanceProps<D>,
      UseGlobalFiltersInstanceProps<D>,
      UseGroupByInstanceProps<D>,
      UsePaginationInstanceProps<D>,
      UseRowSelectInstanceProps<D>,
      UseRowStateInstanceProps<D>,
      UseSortByInstanceProps<D> {
    editable: boolean;
  }

  interface TableState<D extends object = {}>
    extends UseColumnOrderState<D>,
      UseExpandedState<D>,
      UseFiltersState<D>,
      UseGlobalFiltersState<D>,
      UseGroupByState<D>,
      UsePaginationState<D>,
      UseResizeColumnsState<D>,
      UseRowSelectState<D>,
      UseRowStateState<D>,
      UseSortByState<D> {}

  interface ColumnInterface<D extends object = {}>
    extends UseFiltersColumnOptions<D>,
      UseGlobalFiltersColumnOptions<D>,
      UseGroupByColumnOptions<D>,
      UseResizeColumnsColumnOptions<D>,
      UseSortByColumnOptions<D> {}

  interface ColumnInstance<D extends object = {}>
    extends UseFiltersColumnProps<D>,
      UseGroupByColumnProps<D>,
      UseResizeColumnsColumnProps<D>,
      UseSortByColumnProps<D> {}

  interface Cell<D extends object = {}>
    extends UseGroupByCellProps<D>,
      UseRowStateCellProps<D> {}

  interface Row<D extends object = {}>
    extends UseExpandedRowProps<D>,
      UseGroupByRowProps<D>,
      UseRowSelectRowProps<D>,
      UseRowStateRowProps<D> {}
}

const settingStatusMessages = defineMessages({
  pending: {
    id: "devices.table.setting-status.pending",
    defaultMessage: "Pending",
  },
  synced: {
    id: "devices.table.setting-status.synced",
    defaultMessage: "Synced",
  },
  in_progress: {
    id: "devices.table.setting-status.in-progress",
    defaultMessage: "In progress",
  },
});

const tableCellCSS = css`
  &:last-child {
    ${tw`w-full`}
  }

  &:first-of-type {
    ${tw`pl-9`}
  }

  ${tw`relative p-4 text-sm whitespace-nowrap font-body`}
`;

const TableHeadCell = styled.th([tableCellCSS, tw`font-medium`]);
const TableDataCell = styled.td([tableCellCSS]);
const TableRow = styled.tr([
  css`
    ${tw`border-t border-black-epsilon`}
    &:not(:first-of-type):last-child {
      ${tw`border-b border-black-epsilon`}
    }
  `,
]);

const ToggleSelectedDevicesButton = ({
  onSelectToggle,
  onEdit,
  selectedDevices,
}: {
  onSelectToggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onEdit: () => void;
  selectedDevices: number;
}) => {
  const hasSelectedDevices = selectedDevices > 0;

  const label = (
    <span tw="font-body text-black-beta">
      <FormattedMessage
        id="devices.toggle-select-button-label"
        defaultMessage="{hasSelectedDevices, select, true {{selectedDevices} devices selected} other {Select all}}"
        description="Label for toggle devices select button"
        values={{ hasSelectedDevices, selectedDevices }}
      />
    </span>
  );

  return (
    <div tw="flex flex-row">
      <GroupStyleButton onClick={onSelectToggle}>
        <Checkbox
          useMinusIcon
          readOnly
          label={label}
          checked={hasSelectedDevices}
        />
      </GroupStyleButton>
      {hasSelectedDevices && (
        <GroupStyleButton onClick={onEdit}>
          <FormattedMessage
            id="devices.edit-selected-button-label"
            defaultMessage="Edit"
            description="Label for edit selected devices button"
          />
        </GroupStyleButton>
      )}
    </div>
  );
};

const UnassignedEnvironmentMessage = () => (
  <FormattedMessage
    id="devices.table.environment.unassigned"
    defaultMessage="Unassigned"
  />
);
const AllEnvironmentsMessage = () => (
  <FormattedMessage
    id="devices.table.environment.all"
    defaultMessage="All devices"
  />
);

const SelectedEnvironment = () => {
  const { selectedEnvironment } = useFilterSidebar();

  let message;
  if (selectedEnvironment.value === "all") {
    message = <AllEnvironmentsMessage />;
  } else if (selectedEnvironment.value === "unassigned") {
    message = <UnassignedEnvironmentMessage />;
  } else {
    message = "label" in selectedEnvironment && selectedEnvironment.label;
  }

  return (
    <div
      data-test="selected-environment-filter"
      tw="font-body text-black-beta mt-3 text-sm"
    >
      {message}
    </div>
  );
};

const SettingStatusIcon = ({ status }: { status: string }) => {
  if (status === "synced")
    return <CheckCircle color={theme`colors.green-alpha`} />;
  if (status === "in_progress") return <Spinner />;
  if (status === "pending") return <Clock />;

  return null;
};

const SettingStatusHumanReadable = ({ status }: { status: string }) => {
  const intl = useIntl();

  let message;
  if (status === "pending") {
    message = intl.formatMessage(settingStatusMessages.pending);
  }
  if (status === "synced") {
    message = intl.formatMessage(settingStatusMessages.synced);
  }
  if (status === "in_progress") {
    message = intl.formatMessage(settingStatusMessages.in_progress);
  }

  return <>{message}</>;
};

const DeviceListing = ({ devices }: { devices: Device[] }) => {
  const { open: filterSidebarOpen, selectedEnvironment } = useFilterSidebar();
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const columns: Array<Column<Device>> = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      // { Header: "Environment", accessor: "stage.display_name" },
      {
        Header: "Environment",
        accessor: function (row) {
          if (row.stage) {
            return row.stage.display_name;
          } else {
            return null;
          }
        },
        Cell: function EnvironmentCell({ value }) {
          if (!value) {
            return <UnassignedEnvironmentMessage />;
          } else {
            return value;
          }
        },
      },
      {
        Header: "Setting status",
        accessor: "status",
        Cell: function SettingStatusCell({ value }) {
          // add an icon
          return (
            <>
              <div tw="w-4 inline-block mr-2">
                <SettingStatusIcon status={value} />
              </div>
              <SettingStatusHumanReadable status={value} />
            </>
          );
        },
      },
      { Header: "Patient status", accessor: "patient_status" },
    ],
    []
  );

  const data = React.useMemo(
    () => filterDevices(devices, selectedEnvironment),
    [devices.length, selectedEnvironment]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
    isAllRowsSelected,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    toggleAllRowsSelected,
    setGlobalFilter,
  } = useTable<Device>(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: function CheckboxCell({ row }: CellProps<Device>) {
            const IndeterminateCheckbox = React.forwardRef<
              HTMLInputElement,
              { indeterminate?: boolean }
            >(function IndeterminateCheckboxWithRef(
              { indeterminate, ...rest },
              ref: React.MutableRefObject<HTMLInputElement>
            ) {
              const defaultRef = React.useRef<HTMLInputElement>();
              // Don't know how to type this
              const resolvedRef = ref || defaultRef;

              React.useEffect(() => {
                if (resolvedRef.current) {
                  resolvedRef.current.indeterminate = indeterminate;
                }
              }, [resolvedRef, indeterminate]);

              return <Checkbox {...rest} ref={resolvedRef} />;
            });

            // eslint-disable-next-line react/prop-types
            return (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const pageSizeOptions = [10, 25, 50, 100, "all"].map((num) => ({
    label:
      num === "all" ? (
        <FormattedMessage id="pagination.all" defaultMessage="All items" />
      ) : (
        <FormattedMessage
          id="pagination.per-view"
          defaultMessage="{count} per view"
          values={{ count: num }}
        />
      ),

    isActive: num === "all" ? data.length === pageSize : num === pageSize,
    onClick: () => {
      gotoPage(0);
      // Only time when num shouldn't be a number is when num === 'all'
      const pageSize = typeof num === "number" ? num : data.length;
      setPageSize(pageSize);
    },
  }));

  const shouldOpenSidePanel = openSidePanel && selectedFlatRows.length > 0;

  return (
    <div
      css={[
        tw`flex flex-col h-full pt-22`,
        filterSidebarOpen ? tw`ml-80` : tw`ml-24`,
      ]}
    >
      <div css={[tw`mx-6`, shouldOpenSidePanel && tw`pr-96`]}>
        <div>
          <div tw="my-6 flex flex-col">
            <div tw="flex flex-row">
              <h2 tw="font-bold text-xl">
                <FormattedMessage
                  id="devices.heading"
                  defaultMessage="Devices"
                  description="Devices listing heading"
                />
              </h2>
              <ItemCount tw="ml-4" data-test="device-total-count">
                {data.length}
              </ItemCount>
            </div>
            <SelectedEnvironment />
          </div>
          <div tw="rounded-lg shadow-mevia bg-white overflow-x-auto">
            <div tw="p-6 flex flex-row space-x-6">
              <ToggleSelectedDevicesButton
                onSelectToggle={(e) => {
                  e.preventDefault();
                  toggleAllRowsSelected(!isAllRowsSelected);
                }}
                onEdit={() => setOpenSidePanel(!openSidePanel)}
                selectedDevices={selectedFlatRows.length}
              />
              <SearchField
                value={globalFilter}
                id="search"
                onChange={setGlobalFilter}
              />
            </div>
            <table tw="min-w-full text-left" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <TableHeadCell
                          data-sort-attribute={
                            column.id === "selection" ? "select" : column.Header
                          }
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div tw="flex flex-row">
                            {column.render("Header")}
                            {column.canSort && (
                              <div tw="w-4 ml-2">
                                <ChevronUp
                                  color={
                                    column.isSortedDesc === false
                                      ? theme`colors.black-alpha`
                                      : theme`colors.black-delta`
                                  }
                                />
                                <ChevronDown
                                  color={
                                    column.isSortedDesc === true
                                      ? theme`colors.black-alpha`
                                      : theme`colors.black-delta`
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </TableHeadCell>
                      );
                    })}
                  </TableRow>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableDataCell
                            data-attribute={
                              cell.column.id === "selection"
                                ? "select"
                                : cell.column.Header
                            }
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableDataCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              totalItems={data.length}
              perPageOptions={pageSizeOptions}
              currentPage={pageIndex + 1}
              itemsPerPage={pageSize}
              canPrevPage={canPreviousPage}
              canNextPage={canNextPage}
              onNextPage={() => nextPage()}
              onPrevPage={() => previousPage()}
            />
          </div>
        </div>
      </div>
      {shouldOpenSidePanel && (
        <SettingsPanel
          selectedDevices={selectedFlatRows.map((flatrow) => flatrow.original)}
          onCancel={() => setOpenSidePanel(!openSidePanel)}
        />
      )}
    </div>
  );
};

const ItemCount = tw.div`px-3 py-1.5 text-center box-content rounded-2xl leading-none bg-light-blue-delta text-black-alpha`;

const Devices = (): React.ReactElement => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    if (isFetching === false) { return; }

    fetchDevices()
      .then((fetchedDevices: Device[]) => {
        if (!isSubscribed) { return; }

        setDevices(fetchedDevices);
        setIsFetching(false);
        setFetchError(null);
      })
      .catch((error) => {
        if (!isSubscribed) { return; }

        setIsFetching(false);
        setFetchError(error);
      });

      return () => { isSubscribed = false };
  }, [isFetching]);

  return (
    <FilterSidebarProvider>
      <div tw="bg-grey-delta h-screen">
        <Header />
        {!isFetching && fetchError && (
          <FetchError
            message={
              <FormattedMessage
                id="devices.fetching-failed"
                defaultMessage="Failed to fetch devices. If the problem persists, please contact support."
                description="Error message when failed to fetch devices"
              />
            }
            onRetry={() => {
              setIsFetching(true);
            }}
          />
        )}
        {isFetching && (
          <Loader
            label={
              <FormattedMessage
                id="devices.fetching-label"
                defaultMessage="Fetching devices"
                description="Label on loading screen for devices"
              />
            }
          />
        )}
        {!isFetching && !fetchError && (
          <>
            <FilterSidebar devices={devices} />
            <DeviceListing devices={devices} />
          </>
        )}
      </div>
    </FilterSidebarProvider>
  );
};

export default Devices;
