import React from "react";
import { Form } from "react-final-form";
import Button from "../../../components/Button";
import { Device } from "../type";
import { FormattedMessage } from "react-intl";
import CloseIcon from "../../../components/icons/Close";
import tw from "twin.macro";

interface DevicePanelProps {
  selectedDevices: Device[];
  onCancel: () => void;
}

const CloseButtonWrapper = tw.button`w-2 absolute top-3 right-3 text-black-alpha`;

export const CloseButton = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => (
  <CloseButtonWrapper onClick={onClick}>
    <CloseIcon />
  </CloseButtonWrapper>
);

const DevicePanel = (props: DevicePanelProps): React.ReactElement => {
  const { selectedDevices, onCancel } = props;

  const onSubmit = async () => {
    console.log("Submitting");
  };

  return (
    <div tw="bg-white ml-auto p-4 rounded shadow-mevia fixed flex flex-col top-22 right-0 h-full w-96 border-r border-black-epsilon font-body">
      <CloseButton onClick={onCancel} />
      <h2 tw="font-bold text-2xl">
        <FormattedMessage
          id="devices.panel.selected-devices"
          defaultMessage="{count} devices selected"
          values={{ count: selectedDevices.length }}
        />
      </h2>
      <p>
        <FormattedMessage
          id="devices.panel.functionality-description"
          defaultMessage="Change settings for all selected devices"
          description="Description test for panel for editing device settings"
        />
      </p>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div tw="mt-4">
              <label htmlFor="environment" tw="block font-bold">
                <FormattedMessage
                  id="devices.panel.select-environment-label"
                  defaultMessage="Environment"
                  description="Label for environment select input"
                />
              </label>
              <select
                id="environment"
                name="environment"
                autoComplete="environment"
                tw="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option disabled>Select environment</option>
                <option>Europe (dev)</option>
                <option>Canada (prod)</option>
                <option>Asia</option>
              </select>
            </div>
            <div tw="flex mt-4 space-x-3 flex-row justify-end">
              <Button onClick={onCancel} variant="mediumEmphasis">
                <FormattedMessage
                  id="devices.panel.cancel-button"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button disabled={pristine || submitting}>
                <FormattedMessage
                  id="devices.panel.save-button"
                  defaultMessage="Save"
                />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default DevicePanel;
