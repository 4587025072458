import * as React from "react";

function SvgLeave(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.6 0a.8.8 0 01.093 1.595L5.6 1.6H2.4a.8.8 0 00-.795.707L1.6 2.4v11.2a.8.8 0 00.707.795l.093.005h3.2a.8.8 0 01.093 1.595L5.6 16H2.4a2.4 2.4 0 01-2.396-2.259L0 13.6V2.4A2.4 2.4 0 012.259.004L2.4 0h3.2zm6.09 3.368l.076.066 4 4 .007.008c.02.02.038.04.055.063l-.062-.07A.806.806 0 0116 8v.037a.804.804 0 01-.005.05L16 8a.804.804 0 01-.247.578l-3.987 3.988a.8.8 0 01-1.198-1.056l.066-.076L13.268 8.8H5.6a.8.8 0 01-.093-1.595L5.6 7.2l7.667-.001-2.633-2.633a.8.8 0 01-.066-1.056l.066-.076a.8.8 0 011.056-.066z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLeave;
