import React from "react";
import tw, { css, styled, theme } from "twin.macro";
import { keyframes } from "@emotion/react";

const spin = keyframes({
  "0%": { transform: "rotate(0deg) scale(1)" },
  "50%": { transform: "rotate(180deg) scale(1.1)" },
  "100%": { transform: "rotate(360deg) scale(1)" },
});

const Spinner = styled.div([
  tw`w-3 h-3`,
  css`
    --spinner-background: rgba(255, 255, 255, 0.25);
    animation: ${spin} 1s infinite linear;
    border-radius: 100%;
    border-top: var(--border-width) solid var(--spinner-background);
    border-right: var(--border-width) solid var(--spinner-background);
    border-bottom: var(--border-width) solid var(--spinner-background);
    border-left: var(--border-width) solid ${theme`colors.black-alpha`};
    transform: translateZ(0);
  `,
  ({ borderWidth = "2px" }) =>
    css`
      --border-width: ${borderWidth};
    `,
  ({ size = "2em" }) => css`
    height: ${size};
    width: ${size};
  `,
]);

export default Spinner;
