import React, { ReactNode, ReactElement } from "react";
import tw, { css, styled } from "twin.macro";
import { FormattedMessage } from "react-intl";
import VisuallyHidden from "./VisuallyHidden";
import ChevronLeftIcon from "./icons/ChevronLeft";
import ChevronRightIcon from "./icons/ChevronRight";
import DropdownWrapper from "./DropdownWrapper";

const PaginationWrapper = tw.div`flex flex-row justify-end items-center p-6`;
const PaginationRangeWrapper = tw.div`flex flex-row items-center mr-3`;
const RangeDropdownWrapper = tw.div`relative mr-0.5`;

const ToggleButton = tw.button`bg-grey-gamma text-black-beta py-1.5`;

const PageItem = tw.div`m-1.5`;
const PageLinkItem = styled.button([
  tw`inline-block align-middle cursor-pointer hover:text-black-beta focus:text-black-beta`,
  css`
    &[disabled] {
      ${tw`text-black-delta`}
    }
  `,
]);

const PaginationOptionsMenu = styled(DropdownWrapper)([tw`p-1.5 -left-1/2`]);

type PerPageOptionProps = {
  active?: boolean;
};

const PerViewLinkItem = styled.button<PerPageOptionProps>([
  tw`py-1.5 px-3 text-sm no-underline hover:(bg-grey-gamma text-blue-alpha) focus:(bg-grey-gamma text-blue-alpha) `,
  ({ active }) => active && tw`font-bold`,
]);

interface PaginationRangeButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}
const PaginationRangeButton = (props: PaginationRangeButtonProps) => {
  const { onClick, children } = props;
  return (
    <ToggleButton onClick={onClick} data-test="pagination-range-button">
      {children}
    </ToggleButton>
  );
};

interface ItemsPerViewLinkProps {
  children: ReactElement;
  isActive: boolean;
  onClick: () => void;
}

const ItemsPerViewLink = ({
  children,
  isActive,
  onClick,
}: ItemsPerViewLinkProps) => {
  return (
    <PerViewLinkItem active={isActive} onClick={onClick}>
      {children}
    </PerViewLinkItem>
  );
};

const RangeDropdown = (props: {
  label: ReactElement;
  perPageOptions: PerPageOption[];
}) => {
  const { label, perPageOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      const button = event.currentTarget as HTMLButtonElement;
      setAnchorEl(button);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = perPageOptions.map(({ label, isActive, onClick }, index) => {
    return (
      <li key={index}>
        <ItemsPerViewLink
          isActive={isActive}
          onClick={() => {
            onClick();
            handleClose();
          }}
        >
          {label}
        </ItemsPerViewLink>
      </li>
    );
  });

  return (
    <RangeDropdownWrapper>
      <PaginationRangeButton onClick={handleClick}>
        {label}
      </PaginationRangeButton>
      <PaginationOptionsMenu
        testId="pagination-options-menu"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <ul>{options}</ul>
      </PaginationOptionsMenu>
    </RangeDropdownWrapper>
  );
};

interface PaginationRangeProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  perPageOptions: PerPageOption[];
}

const PaginationRange = ({
  currentPage,
  itemsPerPage,
  totalItems,
  perPageOptions,
}: PaginationRangeProps) => {
  const itemOffsetForPreviousPages = (currentPage - 1) * itemsPerPage;
  const firstItemOnPage = Math.min(itemOffsetForPreviousPages + 1, totalItems);
  const lastItemOnPage = Math.min(currentPage * itemsPerPage, totalItems);
  const current = (
    <span>
      {firstItemOnPage} - {lastItemOnPage}
    </span>
  );

  return (
    <PaginationRangeWrapper data-cy="pagination-range">
      <RangeDropdown label={current} perPageOptions={perPageOptions} />
      <FormattedMessage
        id="pagination.range"
        defaultMessage="of {total}"
        values={{ total: totalItems }}
      />
    </PaginationRangeWrapper>
  );
};

export interface PerPageOption {
  label: ReactElement;
  isActive: boolean;
  onClick: () => void;
}

interface PaginationProps {
  totalItems: number;
  perPageOptions: PerPageOption[];
  currentPage: number;
  itemsPerPage: number;
  canPrevPage: boolean;
  canNextPage: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
}

const Pagination = ({
  totalItems = 1,
  perPageOptions,
  currentPage,
  itemsPerPage,
  canPrevPage,
  canNextPage,
  onNextPage,
  onPrevPage,
}: PaginationProps): ReactElement => {
  return (
    <PaginationWrapper>
      <PaginationRange
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        perPageOptions={perPageOptions}
      />
      <PageItem>
        <PageLink
          onClick={onPrevPage}
          disabled={!canPrevPage}
          key="prev"
          testID="prev"
        >
          <div tw="w-4">
            <ChevronLeftIcon />
          </div>
          <VisuallyHidden>
            <FormattedMessage
              id="pagination.previous"
              defaultMessage="Previous"
            />
          </VisuallyHidden>
        </PageLink>
      </PageItem>

      <PageItem>
        <PageLink
          onClick={onNextPage}
          disabled={!canNextPage}
          key="next"
          testID="next"
        >
          <VisuallyHidden>
            <FormattedMessage id="pagination.next" defaultMessage="Next" />
          </VisuallyHidden>
          <div tw="w-4">
            <ChevronRightIcon />
          </div>
        </PageLink>
      </PageItem>
    </PaginationWrapper>
  );
};

interface PageLinkProps {
  children: ReactElement[];
  testID: string;
  disabled: boolean;
  onClick: () => void;
}
const PageLink = React.memo(function NoMemoPageLink({
  children,
  testID,
  disabled,
  onClick,
}: PageLinkProps) {
  return (
    <PageLinkItem
      onClick={onClick}
      disabled={disabled}
      data-test={`pagination-${testID}`}
    >
      {children}
    </PageLinkItem>
  );
});

export default Pagination;
