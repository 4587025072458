import React from "react";
import Popover from "@material-ui/core/Popover";
import tw, { css, styled } from "twin.macro";

const PopoverWrapper = styled(Popover)([
  css`
    & .MuiPaper-root {
      ${tw`shadow rounded-xl whitespace-nowrap`}
    }
  `,
]);

const TOP_OFFSET_PX = 16;

const DropdownWrapper = (props) => {
  const { testId = "", className, anchorEl, children, handleClose } = props;

  let heightOffset;
  if (anchorEl) {
    const anchorRect = anchorEl.getBoundingClientRect();
    heightOffset = anchorRect.height + TOP_OFFSET_PX;
  }

  const anchorOriginVertical =
    props.anchorOrigin?.vertical || heightOffset || "bottom";
  const anchorOriginHorizontal = props.anchorOrigin?.horizontal || "right";

  const anchorOrigin = {
    vertical: anchorOriginVertical,
    horizontal: anchorOriginHorizontal,
  };

  const transformOrigin = props.transformOrigin || {
    vertical: "top",
    horizontal: "right",
  };

  const expanded = Boolean(anchorEl);

  return (
    <PopoverWrapper
      className={className}
      open={expanded}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        "data-test": testId,
      }}
    >
      {children}
    </PopoverWrapper>
  );
};

export default DropdownWrapper;
