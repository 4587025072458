import React, { ReactElement } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import tw from "twin.macro";
import Logo from "../../components/Logo";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router-dom";

const Container = tw.div`
min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8`;

import { logIn } from "./api";
import { saveAuthToken } from "../../utils/persistance";

const messages = defineMessages({
  usernamePlaceholder: {
    id: "login.username-placeholder",
    defaultMessage: "Email address",
    description: "Login form username placeholder",
  },
  passwordPlaceholder: {
    id: "login.password-placeholder",
    defaultMessage: "Password",
    description: "Login form password placeholder",
  },
  unauthorized: {
    id: "login.unathorized",
    defaultMessage: "Username or password is incorrect.",
    description: "Error message in form when login authorization fails",
  },
});

interface FormValues {
  username?: string;
  password?: string;
}

export default function Login(): ReactElement {
  const intl = useIntl();
  const history = useHistory();

  const onSubmit = async (values: FormValues) => {
    const credentials = {
      userName: values.username,
      password: values.password,
    };

    return logIn(credentials)
      .then((json: unknown) => {
        if (typeof json["jwt"] === "string") {
          const jwt = json["jwt"];
          saveAuthToken(jwt);

          // redirect to devices view
          history.push("/authorization");
        }
      })
      .catch(() => {
        return { [FORM_ERROR]: intl.formatMessage(messages.unauthorized) };
      });
  };

  return (
    <Container>
      <Card tw="max-w-lg p-24">
        <Logo />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, submitError }) => (
            <form className="mt-16 space-y-6" onSubmit={handleSubmit}>
              <h2 className="text-lg font-bold font-heading">
                <FormattedMessage
                  id="login.heading"
                  defaultMessage="Login to your account"
                  description="Heading in login form"
                />
              </h2>
              <div className="space-y-6 rounded-md shadow-sm">
                <Field name="username">
                  {({ input }) => (
                    <div>
                      <label className="sr-only">
                        <FormattedMessage
                          id="login.username-label"
                          defaultMessage="Email address"
                          description="Screen reader username input label"
                        />
                      </label>
                      <Input
                        {...input}
                        hasError={submitError}
                        type="text"
                        required
                        placeholder={intl.formatMessage(
                          messages.usernamePlaceholder
                        )}
                      />
                    </div>
                  )}
                </Field>
                <Field name="password">
                  {({ input }) => (
                    <div>
                      <label className="sr-only">
                        <FormattedMessage
                          id="login.password-label"
                          defaultMessage="Password"
                          description="Screen reader password input label"
                        />
                      </label>
                      <Input
                        {...input}
                        hasError={submitError}
                        type="password"
                        required
                        placeholder={intl.formatMessage(
                          messages.passwordPlaceholder
                        )}
                      />
                    </div>
                  )}
                </Field>
              </div>
              {submitError && (
                <div className="mt-2 text-red-alpha">{submitError}</div>
              )}
              <div>
                <Button
                  disabled={submitting}
                  tw="w-full text-center"
                  type="submit"
                >
                  <FormattedMessage
                    id="login.submit-button"
                    defaultMessage="{status, select, submitting {Loading...} other {Login}}"
                    description="Submit button Login form"
                    values={{ status: submitting }}
                  />
                </Button>
              </div>
            </form>
          )}
        />
      </Card>
    </Container>
  );
}
