import * as React from "react";

function SvgFilter(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.729 0l-.08.004a.727.727 0 00-.476 1.193L5.82 7.872v4.49c0 .275.155.527.402.65l2.908 1.454.077.033a.727.727 0 00.975-.683V7.87l5.646-6.674A.727.727 0 0015.27 0H.73zm12.975 1.454L8.9 7.136l-.06.083a.727.727 0 00-.112.387v5.033l-1.454-.727V7.606l-.007-.102a.727.727 0 00-.165-.368L2.295 1.454h11.41z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFilter;
