import * as React from "react";

function SvgChevronLeft(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.08.335a1.113 1.113 0 011.59 0c.406.412.437 1.06.094 1.508l-.094.108L2.717 8l5.953 6.049c.406.412.437 1.06.094 1.509l-.094.107a1.112 1.112 0 01-1.485.095l-.105-.095L.33 8.808A1.157 1.157 0 01.236 7.3l.094-.108L7.08.335z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronLeft;
