import * as React from "react";

function SvgWarning(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.455a6.545 6.545 0 100 13.09 6.545 6.545 0 000-13.09zm.007 8.727a.727.727 0 01.085 1.45L8 11.635a.727.727 0 01-.085-1.45l.092-.004zM8 4.364c.373 0 .68.28.722.642l.005.085V8a.727.727 0 01-1.45.085L7.274 8V5.09c0-.4.325-.726.727-.726z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWarning;
