import React from "react";
import tw, { styled } from "twin.macro";
import WarningIcon from "./icons/Warning";
import { theme } from "twin.macro";

type InputProps = {
  hasError?: boolean;
};

const InputElement = styled.input<InputProps>([
  tw`relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-black-gamma focus:border-black-gamma focus:z-10`,
  ({ hasError = false }) => hasError && tw`border-red-alpha`,
]);

const Input = (
  props: React.ComponentProps<typeof InputElement>
): React.ReactElement => {
  return (
    <div tw="relative">
      <InputElement {...props} />
      {props.hasError && (
        <div tw="transform absolute top-1/2 right-2 -translate-y-1/2 w-4">
          <WarningIcon color={theme`colors.red-alpha`} />
        </div>
      )}
    </div>
  );
};

export default Input;
