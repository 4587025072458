import React from "react";
import tw, { css, styled } from "twin.macro";
import { NavLink } from "react-router-dom";
// @ts-expect-error Not a TS file
import MeviaSymbol from "../../../assets/mevia-symbol@2x.png";
import AccountDropdown from "../../components/AccountDropdown";
import { FormattedMessage } from "react-intl";
import { useLogOut } from "../login/hooks";

const LogoHeader = tw.div`py-7 px-6 flex flex-row `;

const menuItems = [
  {
    path: "/devices",
    label: "Devices",
  },
];

const NavigationList = tw.ul`flex flex-row`;
const NavigationItem = tw.li`px-8 `;

const NavigationLink = styled(NavLink)([
  css`
    &.active {
      ${tw`border-b border-black-alpha hover:border-black-beta`}
    }
  `,
  tw`block text-blue-alpha hover:text-black-beta transition-colors font-body pb-0.5`,
]);

const PrimaryNavigation = () => {
  return (
    <nav>
      <NavigationList>
        {menuItems.map((item) => (
          <NavigationItem key={item.path}>
            <NavigationLink to={item.path}>{item.label}</NavigationLink>
          </NavigationItem>
        ))}
      </NavigationList>
    </nav>
  );
};

const Header = (): React.ReactElement => {
  const logOut = useLogOut();
  return (
    <div tw="bg-white z-50 fixed w-full flex flex-row h-22 items-center border-b border-black-epsilon">
      <LogoHeader tw="border-r w-80">
        <img width="50px" src={MeviaSymbol} className="object-scale-down" />
        <h2 tw="pl-4 font-bold align-middle font-heading">
          <FormattedMessage
            id="header.app-title"
            defaultMessage="Device management"
            description="Application title shown in header"
          />
        </h2>
      </LogoHeader>
      <PrimaryNavigation />
      <div tw="ml-auto mr-4">
        <AccountDropdown onLogOut={logOut} />
      </div>
    </div>
  );
};
export default Header;
