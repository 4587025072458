import { useHistory } from "react-router-dom";
import { clear } from "../../utils/persistance";

export const useLogOut = (): (() => void) => {
  const history = useHistory();

  return () => {
    clear();
    history.replace("/login");
  };
};
