/* eslint-disable react/prop-types */
import React from "react";
import tw, { css, styled } from "twin.macro";
import CheckIcon from "./icons/Check";

const RadioButtonContainer = tw.label`flex flex-grow items-center`;

const hiddenRadioCSS = css`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`;

// Hidden but accessible
const HiddenRadioButton = (props) => (
  <input css={hiddenRadioCSS} type="radio" {...props} />
);

const StyledRadioButton = styled.div([
  tw`relative flex-shrink-0 w-4 h-4 transition-colors ease-in-out border rounded-full cursor-pointer`,
  ({ checked }) =>
    checked
      ? tw`border-blue-alpha bg-blue-alpha`
      : tw`bg-white border-black-delta`,
  css`
    input[type="radio"]:focus + & {
      ${tw`border-blue-alpha`}
    }
  `,
]);

const IconWrapper = styled.div([
  tw`absolute w-3 text-white transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2`,
  ({ checked }) => (checked ? tw`visible` : tw`hidden`),
]);

const RadioButtonIcon = ({ checked }) => (
  <IconWrapper checked={checked}>
    <CheckIcon />
  </IconWrapper>
);

export const Label = styled.span([
  tw`flex flex-grow ml-3 cursor-pointer text-black-alpha`,
]);

export const RadioButton = ({ checked, ...props }) => (
  <>
    <HiddenRadioButton {...props} />
    <StyledRadioButton checked={checked}>
      <RadioButtonIcon checked={checked} noSpacing size="tiny" />
    </StyledRadioButton>
  </>
);

const CustomRadioButtonWithLabel = ({ checked, label, ...props }) => (
  <RadioButtonContainer data-test="radio-button" data-checked={checked}>
    <RadioButton checked={checked} {...props} />
    <Label>{label}</Label>
  </RadioButtonContainer>
);

export default CustomRadioButtonWithLabel;
