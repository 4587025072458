import React from "react";
import tw, { theme } from "twin.macro";
import FilterIcon from "../../../components/icons/Filter";
import { FormattedMessage } from "react-intl";
import CustomRadioButton from "../../../components/CustomRadioButton";
import { useFilterSidebar } from "./context";
import { Device } from "../type";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  unassigned: {
    id: "devices.environment-filter.unassigned-label",
    defaultMessage: "Unassigned",
  },
});

const FilterIconWrapper = tw.div`w-4`;
const FilterButton = ({ onClick }: { onClick: () => void }) => (
  <button onClick={onClick} tw="w-8 h-8 bg-blue-alpha rounded p-2">
    <FilterIconWrapper>
      <FilterIcon color={theme`colors.white`} />
    </FilterIconWrapper>
  </button>
);

const Header = () => {
  const { open, setOpen } = useFilterSidebar();
  return (
    <div tw="p-8">
      <FilterButton
        onClick={() => {
          setOpen(!open);
        }}
      />
      {open && (
        <span tw="ml-3 font-bold text-lg">
          <FormattedMessage
            id="devices.filter-sidebar.heading"
            defaultMessage="Devices"
          />
        </span>
      )}
    </div>
  );
};

const FilterOption = ({
  label,
  checked = false,
  onClick,
  count,
}: {
  label: string;
  checked: boolean;
  onClick: () => void;
  count: number;
}) => {
  return (
    <div tw="flex flex-row">
      <CustomRadioButton
        checked={checked}
        onClick={onClick}
        label={
          <>
            <span tw="text-base">{label}</span>
            <span tw="ml-auto text-base text-black-beta">{count}</span>
          </>
        }
      />
    </div>
  );
};

const sortEnvLabels = (firstEnv, secondEnv) => {
  const [, { label: firstLabel }] = firstEnv;
  const [, { label: secondLabel }] = secondEnv;

  return firstLabel.localeCompare(secondLabel);
};

type StageValue = string;
type StageData = {
  count: number;
  label: string;
};

const FilterOptions = ({
  totalCount,
  environments,
}: {
  totalCount: number;
  environments: [StageValue, StageData][];
}) => {
  const { selectedEnvironment, setSelectedEnvironment } = useFilterSidebar();
  const { value: selectedEnvironmentValue } = selectedEnvironment;
  const sortedEnvironments = environments.sort(sortEnvLabels);

  return (
    <div>
      <div tw="border-b px-8 pb-4">
        <FilterOption
          count={totalCount}
          label={"Show all"}
          onClick={() => setSelectedEnvironment({ value: "all" })}
          checked={selectedEnvironmentValue === "all"}
        />
      </div>
      <div tw="px-8 flex flex-col space-y-2 pt-4">
        {sortedEnvironments.map(([stageValue, { count, label }]) => {
          return (
            <FilterOption
              key={stageValue}
              count={count}
              label={label}
              onClick={() => {
                if (stageValue === "unassigned") {
                  setSelectedEnvironment({ value: stageValue });
                } else {
                  setSelectedEnvironment({
                    value: stageValue,
                    label: label,
                  });
                }
              }}
              checked={selectedEnvironmentValue === stageValue}
            />
          );
        })}
      </div>
    </div>
  );
};

const FilterSidebar = ({
  devices,
}: {
  devices: Device[];
}): React.ReactElement => {
  const { open } = useFilterSidebar();
  const intl = useIntl();

  const environments = devices.reduce((accEnvs, device) => {
    const stageValue = device.stage ? device.stage.id : "unassigned";
    const stageLabel = device.stage
      ? device.stage.display_name
      : intl.formatMessage(messages.unassigned);

    const count = (accEnvs[stageValue]?.count || 0) + 1;

    accEnvs[stageValue] = {
      label: stageLabel,
      count,
    };

    return accEnvs;
  }, {});

  return (
    <div
      data-test="filter-sidebar"
      css={[
        tw`fixed left-0 flex flex-col h-full overflow-y-auto bg-white border-r top-22 pb-22 w-80 border-black-epsilon font-body`,
        open ? tw`w-80` : tw`w-24`,
      ]}
    >
      <Header />
      {open && (
        <FilterOptions
          totalCount={devices.length}
          environments={Object.entries(environments)}
        />
      )}
    </div>
  );
};

export default FilterSidebar;
