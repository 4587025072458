import React from "react";
import tw, { styled } from "twin.macro";
import { FormattedMessage } from "react-intl";
import Input from "../../components/Input";
import InputLabel from "../../components/InputLabel";
import SearchIcon from "../../components/icons/Search";
import VisuallyHidden from "../../components/VisuallyHidden";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  "search-input-placeholder": {
    id: "devices.search-devices-input-placeholder",
    defaultMessage: "Search devices",
  },
});

const SearchWrapper = tw.div`relative w-72 `;

const SearchIconWrapper = tw.div`w-3 absolute top-1/2 left-3 transform -translate-y-1/2 text-black-beta z-10`;

const SearchInput = styled(Input)([
  tw`box-border h-10 pl-10 text-base font-body`,
]);

const SearchField = ({ value, onChange, id }) => {
  const intl = useIntl();
  return (
    <>
      <VisuallyHidden>
        <InputLabel id={id}>
          <FormattedMessage
            id="devices.search-devices-input-label"
            defaultMessage="Search devices"
          />
        </InputLabel>
      </VisuallyHidden>
      <SearchWrapper>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInput
          type="search"
          id={id}
          placeholder={intl.formatMessage(messages["search-input-placeholder"])}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          data-test={id}
        />
      </SearchWrapper>
    </>
  );
};

export default SearchField;
