import tw, { css, styled } from "twin.macro";

const GroupStyleButton = styled.button([
  tw`box-border flex items-center h-10 px-3 py-3 bg-white border text-black-beta border-black-delta hover:border-black-gamma whitespace-nowrap`,
  css`
    &:first-of-type {
      ${tw`rounded-l-lg`}
    }
    &:last-child {
      ${tw`rounded-r-lg`}
    }
  `,
]);

export default GroupStyleButton;
