import React, { useState, ReactElement } from "react";
import tw from "twin.macro";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import {
  logIn,
  getStages,
  Stage,
  MapiUser,
  storeStageCredential,
  deleteStageCredential,
} from "./api";
import { Form, Field } from "react-final-form";
import isValidEmail from "./validateEmail";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import Loader from "../../components/Loader";

const Container = tw.div`
min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8`;
import Card from "../../components/Card";
import Logo from "../../components/Logo";
import Input from "../../components/Input";
import Button from "../../components/Button";

const messages = defineMessages({
  mapiUnauthorized: {
    defaultMessage: "Username or password is incorrect.",
    description: "Error message in form when MAPI authorization fails",
  },
  storeCredentialError: {
    defaultMessage: "Unable to store credentials.",
    description: "Error message in form when MAPI authorization fails",
  },
  deleteCredentialError: {
    defaultMessage: "Unable to delete credentials.",
    description: "Error message in form when MAPI authorization fails",
  },
});

const LargeCard = tw(Card)`max-w-2xl`;

interface IEnvironmentAuth {
  stage: Stage;
  onAuthorizationChange: () => void;
}

interface EnvironmentFormValues {
  username?: string;
  password?: string;
}

const AuthorizedButton = (props: { onClick: (MouseEvent) => void }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <Button
      type="submit"
      variant="mediumEmphasis"
      tw="md:w-28  text-white bg-green-alpha hover:bg-red-beta"
      onMouseEnter={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      onClick={props.onClick}
    >
      <FormattedMessage
        id="mapi-authorization.unauthorize-button"
        defaultMessage="{hovering, select, true {Unauthorize} other {Authorized}}"
        description="Submit label for MAPI environment authorization form"
        values={{ hovering: hovering }}
      />
    </Button>
  );
};

const EnvironmentAuth = (props: IEnvironmentAuth) => {
  const intl = useIntl();
  const { stage, onAuthorizationChange } = props;
  const { display_name, authorized, endpoint_api } = stage;

  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (values: EnvironmentFormValues) => {
    const isEmail = isValidEmail(values.username);
    const credentials = {
      userName: !isEmail && values.username,
      email: isEmail && values.username,
      password: values.password,
    };

    // TODO: show error message about not being validates
    return logIn(endpoint_api, credentials)
      .then((json: MapiUser) => {
        storeStageCredential(stage, json.data.attributes.jwt)
          .then(() => onAuthorizationChange())
          .then(() => setError(null))
          .catch(() => {
            setError(intl.formatMessage(messages.storeCredentialError));
          });
      })
      .catch(() => {
        setError(intl.formatMessage(messages.storeCredentialError));
      });
  };

  const onUnauthorize = (e: React.MouseEvent) => {
    e.preventDefault();
    deleteStageCredential(stage)
      .then(() => onAuthorizationChange())
      .then(() => setError(null))
      .catch(() => {
        setError(intl.formatMessage(messages.deleteCredentialError));
      });
  };

  return (
    <div>
      <div tw="font-body font-bold mb-4">{display_name}</div>
      <Form
        onSubmit={onSubmit}
        validateOnBlur={true}
        render={({ handleSubmit, pristine, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div tw="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
              <Field name="username">
                {({ input }) => (
                  <div className="flex-grow">
                    <label className="sr-only">Email address</label>
                    <Input
                      {...input}
                      disabled={authorized}
                      hasError={submitError}
                      type="text"
                      required
                      placeholder="User name"
                    />
                  </div>
                )}
              </Field>
              <Field name="password">
                {({ input }) => (
                  <div className="flex-grow">
                    <label className="sr-only">Password</label>
                    <Input
                      {...input}
                      disabled={authorized}
                      hasError={submitError}
                      type="password"
                      required
                      placeholder="Password"
                    />
                  </div>
                )}
              </Field>
              {authorized && <AuthorizedButton onClick={onUnauthorize} />}
              {!authorized && (
                <Button
                  disabled={submitting || pristine}
                  type="submit"
                  variant="mediumEmphasis"
                  tw="md:w-28 disabled:opacity-50"
                >
                  <FormattedMessage
                    id="mapi-authorization.authorize-button"
                    defaultMessage="{status, select, submitting {Authorizing...} other {Authorize}}"
                    description="Submit label for MAPI environment authorization form"
                    values={{ status: submitting }}
                  />
                </Button>
              )}
            </div>
            {submitError && (
              <div className="mt-2 text-red-alpha">{submitError}</div>
            )}
            {error && <div className="mt-2 text-red-alpha">{error}</div>}
          </form>
        )}
      />
    </div>
  );
};

const MapiAuthorization = (): ReactElement => {
  const history = useHistory();

  const navigateToDashboard = () => {
    history.push("/devices");
  };

  const { data: stages, isLoading, error, mutate } = useSWR(
    "/api/v1/stages",
    getStages
  );

  return (
    <Container>
      <LargeCard>
        <Logo />
        <div tw="py-8">
          <h2 tw="text-base font-bold font-heading">
            <FormattedMessage
              id="mapi-authorization.heading"
              defaultMessage="Authorize account to edit your devices"
              description="Heading for MAPI environment authorization form"
            />
          </h2>
          <p tw="mt-2 font-body">
            <FormattedMessage
              id="mapi-authorization.description"
              defaultMessage="To be able to edit device settings you need to authorize all your
          accounts. You can proceed without authorization, but it will restrict
          you to only view the devices."
              description="Description in MAPI environment authorization form"
            />
          </p>
        </div>
        {(isLoading || !stages) && (
          <Loader
            label={
              <FormattedMessage
                defaultMessage="Fetching Stages"
                description="Label on loading screen when stages are being fetched"
              />
            }
          />
        )}
        {error && (
          <div tw="py-4">
            <FormattedMessage
              defaultMessage="Error fetching stages - we're retrying continuously. Just sit back and relax for a while :)"
              description="Error message when stages failed to load"
            />
          </div>
        )}
        {!isLoading && !error && (
          <div tw="py-8 border-t border-b space-y-4">
            {stages.map((stage) => (
              <EnvironmentAuth
                stage={stage}
                onAuthorizationChange={mutate}
                key={stage.id}
              />
            ))}
          </div>
        )}
        <div tw="flex flex-col md:flex-row justify-end mt-8 md:space-x-4">
          <Button tw="md:w-28" onClick={navigateToDashboard}>
            <FormattedMessage
              id="mapi-authorization.continue-button"
              defaultMessage="Continue"
              description="Label for 'continue' button on Authorization view"
            />
          </Button>
        </div>
      </LargeCard>
    </Container>
  );
};

export default MapiAuthorization;
