import * as React from "react";

function SvgSearch(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.2 0a7.2 7.2 0 015.625 11.694l2.94 2.94a.8.8 0 01-1.055 1.198l-.076-.066-2.94-2.94A7.2 7.2 0 117.2 0zm0 1.6a5.6 5.6 0 103.903 9.616.36.36 0 01.051-.062l.063-.052A5.6 5.6 0 007.2 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSearch;
