/* eslint-disable react/prop-types */
import React from "react";
import { FormattedMessage } from "react-intl";
import tw from "twin.macro";
import RedoIcon from "./icons/Redo";
import Button from "./Button";

const Block = tw.div`flex flex-col items-center justify-center py-32`;

const FetchError = ({ message = undefined, onRetry }) => (
  <Block>
    <div tw="mb-4">
      {message || (
        <FormattedMessage
          id="fetch-error.generic-message"
          defaultMessage="We are sorry, something went wrong. If the problem persist, please contact support."
        />
      )}
    </div>
    <Button onClick={onRetry} icon={<RedoIcon />}>
      <FormattedMessage
        id="fetch-error.retry-button"
        defaultMessage="Try again"
      />
    </Button>
  </Block>
);

export default FetchError;
