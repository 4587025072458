import * as React from "react";

function SvgChevronDown(props) {
  return (
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.335.83A1.157 1.157 0 011.843.736l.108.094L8 6.783 14.049.83a1.157 1.157 0 011.509-.094l.107.094c.412.405.444 1.043.095 1.485l-.095.105-6.857 6.75a1.157 1.157 0 01-1.508.094l-.108-.094L.335 2.42a1.113 1.113 0 010-1.59z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronDown;
