import tw, { styled } from "twin.macro";

type ButtonProps = {
  variant?: string;
};

// Add themes
const variants = {
  default: tw`bg-orange-alpha hover:bg-orange-beta text-black-alpha`,
  lowEmphasis: tw`bg-transparent hover:bg-transparent text-black-alpha hover:text-black-beta`,
  mediumEmphasis: tw`bg-transparent hover:bg-grey-gamma border-black-gamma`,
  save: tw`text-white bg-green-alpha hover:bg-green-beta`,
  warning: tw`text-white bg-red-alpha hover:bg-red-beta`,
};

const Button = styled.button<ButtonProps>([
  tw`px-4 py-2 font-medium border border-transparent rounded-full font-button text-black-alpha focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
  ({ variant = "default" }) => variants[variant],
  ({ disabled = false }) =>
    disabled && tw`cursor-not-allowed hover:bg-grey-beta bg-grey-beta`,
]);

export default Button;
