const TOKEN_KEY = "AUTHENTICATION_TOKEN";

export const saveAuthToken = (token: string): void => {
  return sessionStorage.setItem(TOKEN_KEY, token);
};

export const getAuthToken = (): string | null =>
  sessionStorage.getItem(TOKEN_KEY);

export const clear = (): void => {
  sessionStorage.removeItem(TOKEN_KEY);
};

export const isEmpty = (): boolean => !sessionStorage.getItem(TOKEN_KEY);
