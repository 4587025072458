import React from "react";
import { FormattedMessage } from "react-intl";
import tw, { css, styled } from "twin.macro";
import { Link } from "react-router-dom";
import DropdownWrapper from "./DropdownWrapper";
import ToggleButton from "./DropdownToggleButton";
import ChevronDownIcon from "../components/icons/ChevronDown";
import ChevronUpIcon from "../components/icons/ChevronUp";
import LeaveIcon from "../components/icons/Leave";

const Wrapper = styled.div`
  position: relative;
`;

const dropdownItemStyles = css`
  ${tw`flex items-center text-left p-2 text-black-alpha transition`}
  min-width: 200px;

  &:hover,
  &:focus {
    ${tw`bg-grey-delta`}
  }

  &:last-child {
    ${tw`border-t border-black-epsilon`}
  }
`;

const DropdownButton = styled.button([dropdownItemStyles]);

const DropdownLink = styled(Link)([
  css`
    ${dropdownItemStyles}
    text-decoration: none;

    &:active,
    &:visited {
      ${tw`text-black-alpha`}
    }
  `,
]);

const DropdownText = tw.span`
  ml-1
`;

const DropdownMenu = tw(DropdownWrapper)``;

const IconWrapper = tw.div`w-4 ml-4`;

const LogOutMenuItem = ({ onLogOut }) => (
  <DropdownButton onClick={onLogOut}>
    <IconWrapper>
      <LeaveIcon />
    </IconWrapper>
    <DropdownText>
      <FormattedMessage
        id="admin-menu.log-out-button-label"
        defaultMessage="Log out"
      />
    </DropdownText>
  </DropdownButton>
);

const AccountDropdown = ({ onLogOut }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      const button = event.currentTarget;
      setAnchorEl(button);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <ToggleButton active={!!anchorEl} onClick={handleClick}>
        <FormattedMessage
          id="admin-menu.account-button-label"
          defaultMessage="Account"
        />
        <div tw="w-4 ml-2">
          {anchorEl ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </ToggleButton>
      <DropdownMenu anchorEl={anchorEl} handleClose={handleClose}>
        <DropdownLink to="/authorization" onClick={handleClose}>
          <DropdownText>
            <FormattedMessage
              id="menu.authorization-button-label"
              defaultMessage="Authorization"
            />
          </DropdownText>
        </DropdownLink>
        <LogOutMenuItem onLogOut={onLogOut} />
      </DropdownMenu>
    </Wrapper>
  );
};

export default AccountDropdown;
