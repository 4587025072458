import React from "react";
import tw, { css, styled, theme } from "twin.macro";

const hiddenCss = css`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`;

const VisuallyHidden = ({ children }) => (
  <span css={hiddenCss} aria-hidden={true}>
    {children}
  </span>
);

export default VisuallyHidden;
