import * as React from "react";

function SvgChevronUp(props) {
  return (
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.192.83A1.157 1.157 0 018.7.736l.108.094 6.857 6.75a1.11 1.11 0 010 1.59 1.157 1.157 0 01-1.508.094l-.108-.094L8 3.217 1.951 9.17a1.157 1.157 0 01-1.509.094L.335 9.17A1.112 1.112 0 01.24 7.685l.095-.105L7.192.83z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronUp;
