import * as React from "react";

function SvgCheckCircle(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.455a6.545 6.545 0 100 13.09 6.545 6.545 0 000-13.09zm4.192 3.258a.889.889 0 01.06 1.174l-.076.083-5.47 5.333a.889.889 0 01-1.158.072l-.083-.072-1.641-1.6A.889.889 0 014.98 8.357l.085.073 1.02.994 4.85-4.727a.889.889 0 011.257.016z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheckCircle;
