import * as React from "react";

function SvgChevronRight(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.83.335A1.112 1.112 0 012.315.24l.105.095 6.75 6.857c.406.412.437 1.06.094 1.508l-.094.108-6.75 6.857a1.113 1.113 0 01-1.59 0 1.157 1.157 0 01-.094-1.508l.094-.108L6.783 8 .83 1.951A1.157 1.157 0 01.736.442L.83.335z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronRight;
