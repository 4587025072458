/* eslint-disable react/prop-types */
import React from "react";
import Spinner from "./Spinner";
import tw, { css, styled } from "twin.macro";

const Label = tw.div`mt-6`;
const Block = styled.div([
  tw`flex flex-col items-center justify-center`,
  ({ minHeight = "80vh" }) =>
    css`
      min-height: ${minHeight};
    `,
]);

const Loader = ({ spinnerSize = "10rem", label }) => (
  <Block>
    <Spinner size={spinnerSize} borderWidth="0.2rem" />
    {label && <Label>{label}</Label>}
  </Block>
);

export default Loader;
