import * as React from "react";

function SvgClose(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.843.24l.108.095L8 6.383 14.049.335a1.143 1.143 0 011.711 1.508l-.095.108L9.616 8l6.05 6.049a1.143 1.143 0 01-1.51 1.711l-.107-.095L8 9.616l-6.049 6.05a1.143 1.143 0 01-1.711-1.51l.095-.107L6.383 8 .335 1.951A1.143 1.143 0 011.727.161l.116.079z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClose;
