/* eslint-disable react/prop-types */
import React from "react";
import tw, { css, styled } from "twin.macro";
// @ts-expect-error Not typed yet
import CheckIcon from "./icons/Check";
// @ts-expect-error Not typed yet
import MinusIcon from "./icons/Minus";

const CheckboxContainer = tw.label`flex flex-grow items-center cursor-pointer`;

const hiddenCheckboxCss = css`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
`;

// Hidden but accessible
const HiddenCheckbox = React.forwardRef(function HiddenCheckboxWithRef(
  props,
  ref
) {
  return <input css={hiddenCheckboxCss} type="checkbox" {...props} ref={ref} />;
});

const StyledCheckbox = styled.div([
  tw`relative flex-shrink-0 w-4 h-4 transition-colors ease-in-out border rounded-sm cursor-pointer`,
  ({ checked }) =>
    checked
      ? tw`border-blue-alpha bg-blue-alpha`
      : tw`bg-white border-black-delta`,
  css`
    input[type="checkbox"]:focus + & {
      ${tw`border-blue-alpha`}
    }
  `,
]);

const IconWrapper = styled.div([
  tw`absolute w-3 text-white transform -translate-x-1/2 left-1/2 top-1/2`,
  ({ checked }) => (checked ? tw`visible` : tw`hidden`),
  ({ useMinusIcon }) => !useMinusIcon && tw`-translate-y-1/2`,
]);

const CheckboxIcon = ({ useMinusIcon, checked }) => (
  <IconWrapper useMinusIcon={useMinusIcon} checked={checked}>
    {useMinusIcon ? <MinusIcon /> : <CheckIcon />}
  </IconWrapper>
);

const labelSizeStyles = {
  small: tw`ml-1.5 text-sm`,
  large: tw`ml-3`,
};

const Label = styled.span([
  tw`flex ml-3 text-black-alpha`,
  ({ size }) => labelSizeStyles[size],
]);
const Checkbox = React.forwardRef(function CheckboxWithRef(
  { checked, label, size = "large", className, useMinusIcon = false, ...props },
  ref
) {
  return (
    <CheckboxContainer data-cy="checkbox" className={className}>
      <HiddenCheckbox checked={checked} {...props} ref={ref} />
      <StyledCheckbox checked={checked}>
        <CheckboxIcon checked={checked} useMinusIcon={useMinusIcon} />
      </StyledCheckbox>
      <Label size={size}>{label}</Label>
    </CheckboxContainer>
  );
});

export default Checkbox;
