import * as React from "react";

function SvgClock(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.455a6.545 6.545 0 100 13.09 6.545 6.545 0 000-13.09zm0 1.454c.373 0 .68.28.722.643l.005.084v3.915l2.507 1.253a.727.727 0 01.36.898l-.034.078a.727.727 0 01-.898.359l-.078-.034-2.91-1.455a.727.727 0 01-.396-.56L7.273 8V3.636c0-.401.325-.727.727-.727z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClock;
