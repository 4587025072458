import * as React from "react";

function SvgMinus(props) {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 0a1 1 0 01.117 1.993L15 2H1A1 1 0 01.883.007L1 0h14z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMinus;
