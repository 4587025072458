import { SelectedEnvironment } from "./context";
import { Device } from "../type";

export const filter = (
  devices: Device[],
  selectedEnvironment: SelectedEnvironment
): Device[] => {
  const { value: selectedEnvironmentValue } = selectedEnvironment;
  if (selectedEnvironmentValue === "all") {
    return devices;
  }
  if (selectedEnvironmentValue === "unassigned") {
    return devices.filter(({ stage }) => !stage);
  }

  return devices.filter(({ stage }) => {
    return stage && stage.id == selectedEnvironmentValue;
  });
};
