import * as React from "react";

export type SelectedEnvironment =
  | { value: "all" }
  | { value: "unassigned" }
  | { value: number | string; label: string };
interface FilterSidebarContextType {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedEnvironment: SelectedEnvironment;
  setSelectedEnvironment: (value: SelectedEnvironment) => void;
}

const FilterSidebarContext = React.createContext<
  FilterSidebarContextType | undefined
>(undefined);

function useFilterSidebar(): FilterSidebarContextType {
  const context = React.useContext(FilterSidebarContext);
  if (!context) {
    throw new Error(
      `useFilterSidebar must be used within a FilterSidebarProvider`
    );
  }
  return context;
}

function FilterSidebarProvider({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement {
  const [open, setOpen] = React.useState(true);
  const [
    selectedEnvironment,
    setSelectedEnvironment,
  ] = React.useState<SelectedEnvironment>({ value: "all" });
  const value = React.useMemo(
    () => ({
      open,
      setOpen,
      selectedEnvironment,
      setSelectedEnvironment,
    }),
    [open, selectedEnvironment]
  );
  return (
    <FilterSidebarContext.Provider value={value}>
      {children}
    </FilterSidebarContext.Provider>
  );
}

export { FilterSidebarProvider, useFilterSidebar };
