import * as React from "react";

function SvgCheck(props) {
  return (
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.483.26a.889.889 0 011.33 1.174l-.073.083-9.778 9.778a.89.89 0 01-1.173.074l-.084-.074L.26 6.851A.889.889 0 011.434 5.52l.083.074 3.816 3.815L14.483.26z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCheck;
