const MAPI_USERS_ROOT = "v2/users";
import { get, post, del } from "../../utils/request";

interface Credentials {
  userName?: string;
  email?: string;
  password: string;
}

export interface Stage {
  id: string | number;
  endpoint_api: string;
  display_name: string;
  authorized: boolean;
}

export interface MapiUser {
  data: {
    id: string | number;
    attributes: {
      jwt: string;
    };
  };
}

export const logIn = async (
  serverRoot: string,
  credentials: Credentials
): Promise<MapiUser> => {
  const loginUrl = `${serverRoot}/${MAPI_USERS_ROOT}/login`;

  const { userName, email, password } = credentials;

  const body = {
    user: { userName, email, password },
  };

  const response = await fetch(loginUrl, {
    method: "POST", //
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new Error("Login failed");
  }
};

export const storeStageCredential = async (
  stage: Stage,
  jwt: string
): Promise<MapiUser> => {
  const credentialUrl = `/api/internal/stages/${stage.id}/credential`;
  const body = {
    credential: { jwt },
  };

  return post(credentialUrl, { body }).then((mapiUser: MapiUser) => {
    return mapiUser;
  });
};

export const deleteStageCredential = async (stage: Stage): Promise<void> =>
  del(`/api/internal/stages/${stage.id}/credential`);

export const getStages = async (): Promise<Stage[]> =>
  get("/api/internal/stages");
